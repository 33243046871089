import { useMutation, useQuery } from "@apollo/client";
import { useState } from "react";
import { Link } from "react-router-dom";
import { FLAG_ENITTY } from "../../graphql/Schemas/Mutations/Mutations";
import { VIEW_QUERY } from "../../graphql/Schemas/Queries/Queries";
import { CheckBox, PrimaryButton, SecondaryButton } from "../Elements/elements";
import { BasicFileUpload } from "../Elements/FileUpload/BasicFileUpload";
import { Tooltip, ClickAwayListener } from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import ClearRoundedIcon from "@material-ui/icons/ClearRounded";

export const Status = ({ data }) => {
  const [Flag] = useMutation(FLAG_ENITTY, { errorPolicy: "all" });
  const [currentStatus, setCurrentStatus] = useState(
    parseInt(data.field_tender_status)
  );

  const [open, setOpen] = useState(false);
  const tenderInfo = JSON.parse(data.tender_info);

  const { data: userData } = useQuery(VIEW_QUERY, {
    variables: {
      viewName: "user_tenders",
      displayId: "rest_export_2",
    },
    fetchPolicy: "network-only",
  });

  const { data: userInfo } = useQuery(VIEW_QUERY, {
    variables: {
      viewName: "user_tenders",
      displayId: "rest_export_3",
    },
    fetchPolicy: "network-only",
  });

  // console.log(userInfo);
  // console.log('ok');

  return (
    <div className="tender-status">
      <div className="tender-status-title">{data.title}</div>
      <div className="tender-teaser__info">
        {!(tenderInfo.status >= 3) && tenderInfo.status != null && data.changed
          ? `Utolsó módosítás dátuma: ${data.changed}`
          : tenderInfo?.info}
      </div>
      <div className="tender-status-checkboxes">
        <p
          style={{
            color:
              ((currentStatus >= 1 && tenderInfo.required?.filled) ||
                currentStatus == "true") &&
              "rgb(177, 177, 177)",
            display: "flex",
            // gap: "5px",
          }}
        >
          {(currentStatus >= 1 && tenderInfo.required?.filled) ||
          currentStatus == "true" ||
          tenderInfo.button == null ? (
            <CheckBox
              checked={
                (currentStatus >= 1 && tenderInfo.required?.filled) ||
                currentStatus == "true" ||
                tenderInfo.button == null
              }
              style={{ alignSelf: "center" }}
            />
          ) : (
            <span style={{ color: "#f44336" }}>
              <ClearRoundedIcon
                style={{ fontSize: "0.8rem", width: "17px" }}
              ></ClearRoundedIcon>{" "}
            </span>
          )}

          {userData ? JSON.parse(userData?.view?.content).map((el, index) => {
            if (index == 0 ) {
              let bundle = JSON.parse(el.status_messages);
              let message = bundle[1];
              return <span>{message}</span>;
            }

          }):null}
        </p>
        {!tenderInfo.required?.filled && tenderInfo.button !== null && (
          <ClickAwayListener onClickAway={() => setOpen(false)}>
            <Alert
              severity="error"
              variant="outlined"
              onClick={() => setOpen((el) => !el)}
              style={{
                padding: "0px 15px",
                marginLeft: "20px",
                cursor: "pointer",
              }}
            >
              <Tooltip
                disableFocusListener
                disableHoverListener
                disableTouchListener
                onClose={() => setOpen((el) => !el)}
                open={open}
                placement="bottom-start"
                title={tenderInfo.required?.fields.map((el) => (
                  <p>{el}</p>
                ))}
              >
                <div>
                  {tenderInfo.required?.info} Kattints ide a részletekért.
                </div>
              </Tooltip>
            </Alert>
          </ClickAwayListener>
        )}
        <p
          style={{
            color:
              (currentStatus > 1 || currentStatus == "true") &&
              "rgb(177, 177, 177)",
          }}
        >
          {currentStatus > 1 || currentStatus == "true" ? (
            <CheckBox checked={true} style={{ verticalAlign: "middle" }} />
          ) : (
            <span style={{ color: "#f44336" }}>
              <ClearRoundedIcon
                style={{ fontSize: "0.8rem", width: "17px" }}
              ></ClearRoundedIcon>{" "}
            </span>
          )}
          {userData ? JSON.parse(userData?.view?.content).map((el, index) => {
            if ( index == 0) {
              let bundle = JSON.parse(el.status_messages);
              let message = bundle[2];
              return <span>{message}</span>;
            }

          }):null}
        </p>
        {(tenderInfo.bundle !== "tender_sr_language_prep" && tenderInfo.bundle !== "tender_admission_prep") && (
          <p
            style={{
              color:
                (currentStatus >= 3 || currentStatus == "true") &&
                "rgb(177, 177, 177)",
              display: "flex",
              gap: "5px",
            }}
          >
            {currentStatus >= 3 || currentStatus == "true" ? (
              <CheckBox checked={true} />
            ) : (
              <span style={{ color: "#f44336" }}>
                <ClearRoundedIcon
                  style={{ fontSize: "0.8rem", width: "17px" }}
                ></ClearRoundedIcon>{" "}
              </span>
            )}
            {userData ? JSON.parse(userData?.view?.content).map((el, index) => {
              if ( index == 0 ) {
                let bundle = JSON.parse(el.status_messages);
                let message = bundle[3];
                return <span>{message}</span>;
              }

            }): null}
          </p>
        )}
      </div>
      <div className="tender-teaser-buttons">
        {(() => {
          // if (tenderInfo.button != null) {
          switch (currentStatus) {
            case 0:
              return (
                <>
                  <Link
                    to={{
                      pathname: "/palyazat",
                      state: {
                        bundle: tenderInfo.bundle,
                        entityType: tenderInfo.entity_type,
                        id: tenderInfo.id,
                        title: data?.title,
                      },
                    }}
                  >
                    <SecondaryButton text="Szerkesztés" />
                  </Link>
                  <PrimaryButton text="Pályázat lezárása" disabled />
                </>
              );
            case 1:
              return (
                <>
                  <Link
                    to={{
                      pathname: "/palyazat",
                      state: {
                        bundle: tenderInfo.bundle,
                        entityType: tenderInfo.entity_type,
                        id: tenderInfo.id,
                        title: data?.title,
                      },
                    }}
                  >
                    <SecondaryButton text="Szerkesztés" />
                  </Link>
                  <PrimaryButton
                    onClick={() => {
                      Flag({
                        variables: {
                          flagName: "submit",
                          entityId: parseInt(tenderInfo.id),
                        },
                      }).then(
                        ({ data: { flagEntity } }) =>
                          flagEntity.success &&
                          setCurrentStatus((prev) => prev + 1)
                      );
                    }}
                    text="Pályázat lezárása"
                    disabled={!tenderInfo.required.filled}
                  />
                </>
              );
            case 2:
              return (
                <>
                  <PrimaryButton text="Pályázat lezárva" disabled />
                  <BasicFileUpload
                    currentStatus={setCurrentStatus}
                    field={{
                      name: "field_tender_signed",
                      bundle: tenderInfo.bundle,
                      entityType: tenderInfo.entity_type,
                      id: tenderInfo.id,
                    }}
                  />
                </>
              );
            case 3:
            case 4:
            case 5:
            case "true":
            default:
              break;
          }
          // }
        })()}
      </div>
    </div>
  );
};
