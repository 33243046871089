import * as yup from "yup";
import React from "react";
import {
  CheckBox,
  InputField,
  Field,
  FileUpload,
  Hierarchical,
  MarkupGenerator,
  AutoComplete,
  Accordion,
  CheckBoxes,
  Conditionals,
  CshsConditional,
} from "../components/Elements/elements";
import { Controller } from "react-hook-form";
import { MenuItem, Select } from "@material-ui/core";

const generateField = (
  field,
  register,
  control,
  errors,
  parent,
  unregister,
  setValue,
  disabled,
  wrapper = field.name
) => {
  switch (field.widgetType) {
    case "text_textarea_with_summary":
      return (
        <Field
          wrapper={wrapper}
          field={field}
          value={<MarkupGenerator string={field.value[0]?.key} />}
        />
      );
    case "options_select":
      if (field.conditional) {
        return (
          <Conditionals
            field={field}
            register={register}
            control={control}
            errors={errors}
            setValue={setValue}
            disabled={disabled}
          />
        );
      } else {
        let winX = null;
        let winY = null;

        // window.addEventListener("scroll", function () {
        //   if (winX !== null && winY !== null) {
        //     window.scrollTo(winX, winY);
        //   }
        // });
        const paragraphId = parent ? parent.match(/-(\d+)$/)?.[1] : null;
        const paragraphFieldName = `${field.name}-${paragraphId}`;
        const error = errors?.[field.name]?.message ?
          errors?.[field.name] : errors?.[paragraphFieldName];
        return (
          <Field
            wrapper={wrapper}
            field={field}
            error={error?.message}
            value={
              <Controller
                render={({ field: { onChange, value, ref } }, ...props) => (
                  <div ref={ref} tabIndex="-1" className="outline-none">
                    <Select
                      onChange={(e) => onChange(e.target.value)}
                      value={value}
                      variant="outlined"
                      error={!!error}
                      displayEmpty
                      onOpen={() => {
                        winX = window.scrollX;
                        winY = window.scrollY;
                      }}
                      onClose={() => {
                        winX = null;
                        winY = null;
                      }}
                      disabled={disabled}
                      style={{
                        backgroundColor: disabled ? "#FAFAFA" : "",
                      }}
                      className="select"
                      MenuProps={{
                        disableScrollLock: true,
                        disablePortal: true,
                        className: "select-paper",
                      }}
                    >
                      <MenuItem value={undefined} disabled>
                        Válassz egy lehetőséget
                      </MenuItem>
                      {field.allowedValues?.map(({ key, value }) => (
                        <MenuItem
                          selected={key == field.value[0]?.key}
                          value={key}
                          style={{
                            whiteSpace: "unset",
                            wordBreak: "break-word",
                          }}
                        >
                          {value}
                        </MenuItem>
                      ))}
                    </Select>
                  </div>
                )}
                name={parent ? parent + "." + field.name : field.name}
                defaultValue={field.value[0]?.key || undefined}
                control={control}
              />
            }
          />
        );
      }
    case "media_library_widget":
    case "image_image":
    case "file_generic":
      return (
        <Field
          wrapper={wrapper}
          field={field}
          error={errors?.[field.name]?.message}
          value={
            <FileUpload
              error={!!errors?.[field.name]}
              control={control}
              register={register}
              field={field}
              setValue={setValue}
              disabled={disabled}
            />
          }
        />
      );
    case "entity_reference_paragraphs":
    case "report_entity_reference_paragraphs":
      return (
        <Accordion
          unregister={unregister}
          field={field}
          register={register}
          control={control}
          // parent={parent}
          disabled={disabled}
          errors={errors}
        />
      );
    case "cshs":
      const data = {};
      field.allowedValues.forEach(({ parentKey, value, key: locationKey }) => {
        if (data[parentKey]) {
          data[parentKey].push({ value, locationKey });
        } else {
          data[parentKey] = [{ value, locationKey }];
        }
      });
      if (field.conditional) {
        return (
          <CshsConditional
            field={field}
            register={register}
            control={control}
            errors={errors}
            setValue={setValue}
            data={data}
            disabled={disabled}
          />
        );
      } else {
        return (
          <Field
            wrapper={wrapper}
            field={field}
            error={errors?.[field.name]?.message}
            value={
              <Hierarchical
                disabled={disabled}
                field={field}
                control={control}
                data={data}
              />
            }
          />
        );
      }
    case "entity_reference_autocomplete":
    case "readonly_field_widget":
      const allowedValues = field.allowedValues?.reduce(
        (results, { key, value }) => {
          return { ...results, [key]: { key, value } };
        },
        {}
      );
      const defaultValue = field.referenceValues?.map(({ id }) => {
        return allowedValues[id];
      });
      return (
        <Field
          wrapper={wrapper}
          field={field}
          error={errors?.[field.name]?.message}
          value={
            <AutoComplete
              control={control}
              name={parent ? parent + "." + field.name : field.name}
              widgetType={field.widgetType}
              multiple={field.cardinality != 1}
              options={field.allowedValues?.map(({ key, value }) => ({
                key,
                value,
              }))}
              defaultValue={
                defaultValue?.length == 1 ? defaultValue[0] : defaultValue
              }
              getOptionLabel={(option) => option.value}
              disabled={field.widgetType === "readonly_field_widget" || disabled}
              renderInput={(params) => (
                <InputField
                  {...params}
                  error={!!errors?.[field.name]}
                  placeholder={field.placeholder}
                  autocomplete="true"
                  margin="normal"
                  disabled={disabled}
                />
              )}
            />
          }
        />
      );
    /*case "readonly_field_widget":
      const readonlyAllowedValues = field.allowedValues?.reduce(
        (results, { key, value }) => {
          return { ...results, [key]: { key, value } };
        },
        {}
      );
      const readonlyDefaultValue = field.referenceValues?.map(({ id }) => {
        return readonlyAllowedValues[id];
      });
      return (
        <h4>
          {readonlyDefaultValue?.length == 1 ?
            readonlyDefaultValue[0].value : readonlyDefaultValue}
        </h4>
      );*/
    case "boolean_checkbox":
      return (
        <Field
          wrapper={wrapper}
          field={field}
          error={errors?.[field.name]?.message}
          value={
            <CheckBox
              register={{ ...register(`${field.name}`) }}
              defaultChecked={field.value[0]?.key == "1"}
              disabled={disabled}
            />
          }
        />
      );
    case "boolean_checkboxes":
    case "options_buttons":
      return (
        <Field
          wrapper={wrapper}
          field={field}
          error={errors?.[field.name]?.message}
          value={
            <CheckBoxes disabled={disabled} field={field} control={control} />
          }
        />
      );
    case "datetime_default":
      return (
        <Field
          wrapper={wrapper}
          field={field}
          error={errors?.[field.name]?.message}
          value={
            <Controller
              render={({ field: { onChange , ref} }, ...props) => (
                <div ref={ref} tabIndex="-1">
                  <InputField
                    defaultValue={field.value[0]?.key.slice(0, 10)}
                    type="date"
                    inputProps={{
                      min: field.minimum,
                      max: field.maximum,
                    }}
                    onChange={(e) => onChange(e.target.value)}
                    error={!!errors?.[field.name]}
                    placeholder={field.placeholder}
                    disabled={disabled}
                    {...props}
                  />
                </div>
              )}
              name={parent ? parent[field.name] : field.name}
              defaultValue={field.value[0]?.key.slice(0, 10)}
              control={control}
            />
          }
        />
      );
    case "state":
      return (<Field
        wrapper={wrapper}
        field={field}
        error={errors?.[field.name]?.message}
        value={
          <Select disabled={disabled} field={field} control={control} />
        }
      />);
    case null:
      if (field.children) {
        const data = field.children;
        return [
          <h2 className="breki-title">{field.label}</h2>,
          Fields({
            data,
            register,
            control: control,
            errors,
            unregister,
            setValue,
            disabled,
          }),
        ];
      }
    default:
      let ref = parent ? `${parent + "." + field.name}` : `${field.name}`;
      return (
        <Field
          wrapper={wrapper}
          error={errors?.[field.name]?.message}
          field={field}
          hidden={field.type === "value"}
          value={
            <InputField
              defaultValue={field.value[0]?.key}
              error={!!errors?.[field.name]}
              placeholder={field.placeholder}
              disabled={disabled}
              rows={field.widgetType === "string_textarea" && 4}
              multiline={field.widgetType === "string_textarea"}
              type={field.widgetType === "number" ? "number" : field.type}
              className={field.widgetType === "number" ? "number" : ""}
              inputProps={{
                ...register(ref),
                step: field.type === "decimal" && 0.01,
                min: field.minimum,
                max: field.maximum,
              }}
            />
          }
        />
      );
  }
};

const Fields = ({
  data,
  register,
  control,
  errors,
  unregister,
  setValue,
  disabled,
  parent = false,
}) => {
  if (data) {
    let newData = data.slice();
    newData.sort((a, b) => a.weight - b.weight);
    return newData.map((field) => {
      return generateField(
        field,
        register,
        control,
        errors,
        parent,
        unregister,
        setValue,
        disabled,
      );
    });
  }
  return null;
};

const ModifyData = (dataForm) => {
  const input = {};
  const iteration = [];
  for (const [key, value] of Object.entries(dataForm)) {
    if (key.includes("-")) {
      const newKey = key.split("-")[0];
      const id = key.split("-")[1].includes("new")
        ? {}
        : { id: key.split("-")[1] };
      let values = {};
      if (typeof value === "object") {
        for (const [key, item] of Object.entries(value)) {
          if (typeof item == "object" && item?.data?.hasOwnProperty("key")) {
            values[key] = item?.data.key;
          } else {
            values[key] = typeof item === "undefined" ? "" : item;
          }
        }
      }

      if (input[newKey]) {
        input[newKey].value.push({...(Object.keys(values).length > 0 ? values : value), ...id});
      } else {
        input[newKey] = {
          name: newKey,
          value: [{...(Object.keys(values).length > 0 ? values : value), ...id}],
        };
        iteration.push(newKey);
      }
      continue;
    }
    if (key.includes("$")) {
      const newKey = key.split("$")[0];
      input[newKey] = {
        name: newKey,
        value: value != undefined ? `${value}` : "",
      };
      continue;
    }
    if (typeof value === "boolean") {
      input[key] = { name: key, value: `${~~value}` };
      continue;
    }
    if (typeof value == "object" || typeof value == "array") {
      let values = [];
      if (value?.data != undefined) {
        if (Array.isArray(value.data)) {
          value.data.map(({ key, id }) => {
            values.push(key || id);
          });
        } else {
          Array.of(value.data).map(({ key, id }) => {
            values.push(key || id);
          });
        }
        input[key] = {
          name: key,
          value: values.join(","),
        };
      } else {
        if (!isNaN(Date.parse(value))) {
          const timestamp = Date.parse(value);
          const date = new Date(timestamp);
          input[key] = {
            name: key,
            value: date.toISOString().split(".")[0],
          };
        } else {
          input[key] = {
            name: key,
            value: "",
          };
        }
      }
      continue;
    }
    input[key] = { name: key, value: value != undefined ? `${value}` : "" };
  }
  iteration.forEach((el) => {
    input[el].value = JSON.stringify(input[el].value);
  });
  return { input: Object.values(input) };
};

const getYupSchema = (fields) => {
  return fields?.reduce((schema, config) => {
    if (config?.children) {
      config.children.forEach((element) => {
        if (element.conditional) {
          element.conditional.forEach((conditional) => {
            return generateSchema(schema, conditional.children[0]);
          });
        }
        return generateSchema(schema, element);
      });
    } else {
      return generateSchema(schema, config);
    }
    return schema;
  }, {});
};

const generateSchema = (schema, config) => {
  const { name, type, widgetType } = config;
  if (type == "value") {
    return schema;
  }
  let fieldType = type;
  if (
    type == "email" ||
    type == "list_string" ||
    type == "textfield" ||
    type == "password"
  ) {
    fieldType = "string";
  }
  if (type == "entity_reference" || type === "entity_reference_revisions") {
    fieldType = "object";
  }
  if (type == "decimal" || type == "integer" || type == "float") {
    fieldType = "number";
  }
  if (type == "datetime") {
    fieldType = "date";
  }
  if (type == "state") {
    fieldType = "select";
  }
  if (!yup[fieldType]) {
    return schema;
  }

  const validations = [];
  let validator = yup[fieldType]();
  Array.of(config).map(
    ({ isRequired, maxLength, maximum, minimum, fakeRequired }) => {
      if (isRequired && !fakeRequired) {
        if (type === 'entity_reference_revisions') {
          validations.push({
            type: "min",
            params: [1, "Legalább egy kötelező!"],
          });
        } else {
          // validations.push({
          //   type: "required",
          //   params: ["A mező nem lehet üres."],
          // });
        }
      }
      if (maxLength != null) {
        validations.push({
          type: "max",
          params: [
            maxLength,
            `Nem lehet hosszabb, mint ${maxLength} karakter.`,
          ],
        });
      }
      if (maximum != null) {
        validations.push({
          type: "max",
          params: [maximum, `A maximális érték ${maximum} lehet.`],
        });
      }
      if (minimum != null) {
        validations.push({
          type: "min",
          params: [minimum, `A minimális érték ${minimum}.`],
        });
      }
      if (type == "email") {
        validations.push({
          type: "email",
          params: [`Valós e-mail címet adj meg!`],
        });
      }
      if (type == "password") {
        validations.push({
          type: "min",
          params: [8, "Minimum 8 karaktert kell tartalmaznia."],
        });
        validations.push({
          type: "matches",
          params: [/[a-z]/, "Tartalmaznia kell kis betűt."],
        });
        validations.push({
          type: "matches",
          params: [/[A-Z]/, "Tartalmaznia kell nagy betűt."],
        });
        validations.push({
          type: "matches",
          params: [/[0-9]/, "Tartalmaznia kell számot."],
        });
      }
      if (name == "pass2") {
        validations.push({
          type: "oneOf",
          params: [[yup.ref("pass1"), null], "A jelszavaknak egyezniük kell!"],
        });
      }
      if (fieldType == "date") {
        validations.push({
          type: "typeError",
          params: [`Valós dátumot adj meg!`],
        });
      }
      if (fieldType == "boolean" && isRequired) {
        validations.push({
          type: "boolean",
        });
        validations.push({
          type: "oneOf",
          params: [[true], "Az adatkezelési tájékoztató elfogadása kötelező!"],
        });
      }
      if (fieldType == "number") {
        validations.push({
          type: "transform",
          params: [(v) => (v === "" || Number.isNaN(v) ? null : parseFloat(v))],
        });
        validations.push({
          type: "typeError",
          params: [`Számot adj meg!`],
        });
      }
      validations.push({
        type: "nullable",
        params: [],
      });
    }
  );
  validations.forEach((validation) => {
    const { params, type } = validation;
    if (!validator[type]) {
      return;
    }
    validator = validator[type](...params);
  });
  if (widgetType == "cshs") {
    return schema
  } else {
    schema[name] = validator;
  }
  return schema;
};

export const useFields = {
  ModifyData,
  Fields,
  getYupSchema,
};
